interface PermissionInterface {
  [key: string]: string;
}

export enum Permission {
  CanViewPartnerProfiles = 'CanViewPartnerProfiles',
  CanDeletePartnerProfiles = 'CanDeletePartnerProfiles',
  CanViewAdHocDistributions = 'CanViewAdHocDistributions',
  CanViewStocks = 'CanViewStocks',
  CanViewMonthlyReports = 'CanViewMonthlyReports',
  CanCreateAdHocs = 'CanCreateAdHocs',
  CanViewPartnershipOpportunity = 'CanViewPartnershipOpportunity',
  CanSubmitOpportunity = 'CanSubmitOpportunity',
  CanCreatePartnershipOpportunity = 'CanCreatePartnershipOpportunity',
  CanAccessPartnerships = 'CanAccessPartnerships',
  CanAccessInvoicing = 'CanAccessInvoicing',
  CanCreateUser = 'CanCreateUser',
  CanAccessPartnershipsUsers = 'CanAccessPartnershipsUsers'
}

export const PERMISSIONS: PermissionInterface = {
  CanViewPartnerProfiles: 'profiles.view_partnerprofile',
  CanDeletePartnerProfiles: 'profiles.delete_partnerprofile',
  CanViewAdHocDistributions: 'reports.view_adhocdistributionreportsubmission',
  CanViewStocks: 'stocks.can_view_stocks',
  CanViewMonthlyReports: 'reports.view_distributionreport',
  CanCreateAdHocs: 'reports.add_adhocdistributionreportsubmission',
  CanViewPartnershipOpportunity: 'opportunities.can_view_fla_opportunity',
  CanSubmitOpportunity: 'opportunities.can_submit_fla_opportunity',
  CanCreatePartnershipOpportunity: 'opportunities.can_add_fla_opportunity',
  CanAccessPartnerships: 'accounts.can_access_fla_module',
  CanAccessInvoicing: 'accounts.can_access_invoicing_module',
  CanCreateUser: 'accounts.can_create_user',
  CanAccessPartnershipsUsers: 'accounts.can_access_fla_user_module'
};
