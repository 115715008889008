import {
  useReportingInfoBanner,
  usePartnershipsInfoBanner
} from '../../../services';

export const useAnnouncements = () => {
  const { data: reportingInfoBanners = [] } = useReportingInfoBanner();

  const { data: partnershipsInfoBanners = [] } = usePartnershipsInfoBanner();

  const banners = [...reportingInfoBanners, ...partnershipsInfoBanners];

  return {
    banners
  };
};
