import { hasPermission } from '../../helpers';
import { useUserProfile } from '../../context';
import { PropsWithChildren, useMemo } from 'react';
import { NO_ACCESS_PATH } from '../../constants';

export const PrivateRoute = ({
  children,
  permissionCheck
}: PropsWithChildren<{ permissionCheck: string | null }>) => {
  const { userProfile } = useUserProfile();

  const canAccessReporting = useMemo(
    () =>
      window.location.pathname.includes('reporting') &&
      userProfile?.allowedModules?.canAccessReporting,
    [userProfile?.allowedModules?.canAccessReporting]
  );

  const canAccessPartnerships = useMemo(
    () =>
      window.location.pathname.includes('partnerships') &&
      userProfile?.allowedModules?.canAccessPartnerships,
    [userProfile?.allowedModules?.canAccessPartnerships]
  );

  const canAccessInvoicing = window.location.pathname.includes('invoicing');

  const isAccessingOtherPathname = useMemo(
    () =>
      ['reporting', 'partnerships', 'invoicing'].every(
        (pathname) => !window.location.pathname.includes(pathname)
      ),
    []
  );

  const canAccessProtectedModule = useMemo(
    () =>
      canAccessReporting ||
      canAccessPartnerships ||
      canAccessInvoicing ||
      isAccessingOtherPathname,
    [
      canAccessPartnerships,
      canAccessReporting,
      canAccessInvoicing,
      isAccessingOtherPathname
    ]
  );

  const canAccess = useMemo(
    () =>
      userProfile && canAccessProtectedModule && permissionCheck
        ? hasPermission(userProfile?.permissions, permissionCheck)
        : false,
    [canAccessProtectedModule, permissionCheck, userProfile]
  );

  if (!canAccess) window.location.replace(NO_ACCESS_PATH);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return canAccess ? <>{children}</> : null;
};
